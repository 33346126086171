<template>
	<div class="page-chapter">

		<div class="featured-image">
			<AtomImage 
				v-if="loading"
				:absolute="true"
				:loading="loading"
				/>
			<AtomImage 
				v-else
				:src="chapter.chapter ? chapter.chapter.image : null"
				:absolute="true"
				/>

			<div 
				v-if="!loading && chapter.chapter.name"
				class="title-overlay"
				>
				<AtomHeading 
					color="white" 
					align="left"
					size="l"
					weight="normal"
					>
					{{ chapter.chapter.name }}
				</AtomHeading>
			</div>
		</div>

		<div class="container typography-styles">

			<div 
				class="chapter-header"
				:class="{ sticky: chapterHeaderIsSticky }"
				ref="chapterHeading"
				>
				
				<AtomIcon
					icon="arrow-left"
					color="grey"
					size="medium"
					@click="backToDocument"
					/>
				<AtomIcon
					icon="font-size"
					color="grey"
					size="medium"
					/>
				<div class="bookmark-icon">
					<AtomIcon
						icon="bookmark-flat"
						color="grey"
						size="large"
						/>
				</div>
			</div>

			<div 
				v-if="loading"
				class="chapter-description typography-styles"
				>
				<AtomLoadingText
					:lines="5"
					/>
			</div>
			<div 
				v-if="!loading && chapter.chapter.description"
				class="chapter-description typography-styles"
				>
				<div class="chapter-number">
					{{ chapter.chapter.order }}
				</div>
				<div class="chapter-text">
					{{ chapter.chapter.description }}
				</div>
			</div>

			

			<div class="blocks-container">
				<template v-if="loading">

					<AtomLoadingText
						v-for="i in 8"
						:key="i"
						:lines="12"
						/>

				</template>
				<template v-else>

					<div 
						v-if="!chapter"
						class="no-content"
						>
						Chapter not available
					</div>

					<template v-else>

						<AtomContentBlock
							v-for="(block, index) in chapter.chapter.blocks"
							:key="index"
							:block="block"
							/>

						<div class="chapter-nav">
							<div class="prev-chapter">
								<div @click="gotoChapter(chapter.previousChapter)">
									<MoleculeHeadingWithIcon
										v-if="chapter.previousChapter"
										heading="Previous Chapter"
										headingSize="s"
										headingColour="grey"
										icon="arrow-left"
										iconSide="left"
										iconColour="grey"
										/>
								</div>
							</div>
							<div class="next-chapter">
								<div @click="gotoChapter(chapter.nextChapter)">
									<MoleculeHeadingWithIcon
										v-if="chapter.nextChapter"
										heading="Next Chapter"
										headingSize="s"
										headingColour="grey"
										icon="arrow-right"
										iconSide="right"
										iconColour="grey"
										/>
								</div>
							</div>
						</div>

					</template>

				</template>

			</div>			
			
		</div>
	</div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

import AtomLoadingText from '@/components/atoms/AtomLoadingText.vue'
import AtomHeading from '@/components/atoms/AtomHeading.vue'
import AtomIcon from '@/components/atoms/AtomIcon.vue'
import AtomImage from '@/components/atoms/AtomImage.vue'
import AtomContentBlock from '@/components/atoms/AtomContentBlock.vue'
import MoleculeHeadingWithIcon from '@/components/molecules/MoleculeHeadingWithIcon.vue'

export default {
	name: 'PageChapter',
	components: {
		AtomLoadingText,
		AtomHeading,
		AtomIcon,
		AtomImage,
		AtomContentBlock,
		MoleculeHeadingWithIcon,
	},
	data: () => {
		return {
			loading: true,
			chapterID: null,
			chapterHeaderIsSticky: false,
			layoutOuter: null,
			initialChapterHeadingOffset: null,
		}
	},
	mounted() {
		// get the id from the route params
		this.chapterID = this.$route.params.id
		this.getChapter(this.chapterID).then(() => {
			this.loading = false
			// console.log('chapter loaded', this.chapter)
		})

		this.layoutOuter = document.querySelector('.layout-main-outer')
		
		// add scroll event listener
		this.layoutOuter.addEventListener('scroll', this.isChapterHeadingSticky)

		// add scroll event listener
		this.layoutOuter.addEventListener('scroll', this.isChapterHeadingSticky)
	},
	beforeUnmount() {
		// remove scroll event listener
		this.layoutOuter.removeEventListener('scroll', this.isChapterHeadingSticky)
	},
	methods: {
		...mapActions('documents', {
			getChapter: 'getChapter',
		}),
		gotoChapter(chapter) {
			// router push to chapter id
			console.log('gotoChapter', chapter)
			this.$router.replace({ name: 'Chapter', params: { id: chapter.id } })
		},
		backToDocument(){
			// router push to document
			// console.log('back', this.chapter.document_id)
			if(this.chapter.chapter && this.chapter.chapter.document_id){
				this.$router.push({ name: 'Document', params: { id: this.chapter.chapter.document_id } })
			}
		},
		isChapterHeadingSticky() {
			// see if the chapter heading is above the parent container .layout-main-outer bounds, if so, chapterHeaderIsSticky true, else false
			const chapterHeading = this.$refs.chapterHeading

			if(this.initialChapterHeadingOffset == null){
				this.initialChapterHeadingOffset = chapterHeading.offsetTop
			}else{
				if (chapterHeading && this.layoutOuter) {
					let isSticky = chapterHeading.offsetTop != this.initialChapterHeadingOffset
					this.chapterHeaderIsSticky = isSticky
				}
			}

		}
	},
	computed: {
		...mapGetters('documents', {
			chapter: 'getChapter',
		}),
	}
}
</script>

<style lang="sass">

.page-chapter
	display: flex
	flex-direction: column
	border-radius: $borderRadiusLarge $borderRadiusLarge 0 0
	background-color: $lightGrey
	width: 100%
	min-height: $mainContentWindowHeight
	overflow: visible
	padding: 0 0 20px 0

	.featured-image
		width: 100%
		height: 230px
		background-color: lighten($midGrey, 15%)
		margin-bottom: 0px
		display: flex
		position: relative
		border-radius: $borderRadiusLarge $borderRadiusLarge 0 0
		overflow: hidden

		.title-overlay
			position: absolute
			bottom: 0
			left: 0
			right: 0
			padding: 60px $sidePaddingSmall 15px $sidePaddingSmall
			background: linear-gradient(0deg, rgba(#333, 0.3), rgba(#333, 0.0))

	.chapter-description
		display: flex
		gap: 10px
		padding: 0 0 20px 0
		font-family: $mainFontBold

		.chapter-number
			font-size: 20px
			margin-top: -3px

		.chapter-text
			font-size: 14px
			flex: 1

	.chapter-header
		position: sticky
		top: 0
		z-index: 3
		margin: 0 (-$sidePaddingSmall) 20px
		padding: 0 $sidePaddingSmall
		display: flex
		align-items: center
		justify-content: space-between
		transition: all 300ms
		height: 40px

		&.sticky
			background-color: rgba($white, 0.75)
			backdrop-filter: blur(5px)

		.bookmark-icon
			height: 40px

	.blocks-container
		padding: 0 0 20px 0
		display: flex
		flex-direction: column
		gap: 12px

		.chapter-nav
			display: flex
			align-items: center
			justify-content: space-between
			gap: 10px


</style>