<template>
	<div class="app-grad-bg" />
	<router-view v-slot="{ Component }">
		<transition name="layout-fade" mode="out-in">
			<component :is="Component" />
		</transition>
	</router-view>
</template>

<script>
export default {
	name: 'App',
}
</script>

<style lang="sass">

.app-grad-bg
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	z-index: -1
	background-image: url('~@/assets/images/bg-grad-blue.png')
	background-size: 100% 100%

</style>