import { createWebHistory, createRouter } from "vue-router"
import store from './store/store'

import LayoutUnAuth from "@/layouts/LayoutUnAuth.vue"
import LayoutMain from "@/layouts/LayoutMain.vue"

import PageStart from "@/views/PageStart.vue"
import PageLogin from "@/views/PageLogin.vue"
import PageRegister from "@/views/PageRegister.vue"
import PageDashboard from "@/views/PageDashboard.vue"
import PageDocument from "@/views/PageDocument.vue"
import PageChapter from "@/views/PageChapter.vue"

const routes = [
	{
		path: "/",
		component: LayoutUnAuth,
		children: [
			{
				path: '',
				name: "Start",
				component: PageStart,
			}
		]
	},
	{
		path: "/login",
		component: LayoutUnAuth,
		children: [
			{
				path: '',
				name: "Login",
				component: PageLogin,
			}
		]
	},
	{
		path: "/register",
		component: LayoutUnAuth,
		children: [
			{
				path: '',
				name: "Register",
				component: PageRegister,
			}
		]
	},
	{
		path: "/dashboard",
		component: LayoutMain,
		children: [
			{
				path: '',
				name: "Dashboard",
				component: PageDashboard,
				meta: { requiresAuth: true }
			}
		]
	},
	{
		path: "/document/:id",
		component: LayoutMain,
		children: [
			{
				path: '',
				name: "Document",
				component: PageDocument,
				meta: { requiresAuth: true }
			}
		]
	},
	{
		path: "/chapter/:id",
		component: LayoutMain,
		children: [
			{
				path: '',
				name: "Chapter",
				component: PageChapter,
				meta: { requiresAuth: true }
			}
		]
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

// Global navigation guard
router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		// Check if the user is authenticated
		if (!store.getters['auth/isAuthenticated']) {
			// Redirect to the login page
			next({ name: 'Login' });
		} else {
			next();
		}
	} else if (to.name === 'Login' && store.getters['auth/isAuthenticated']) {
		// If the user is already authenticated and trying to access the login page, redirect to the dashboard
		next({ name: 'Dashboard' }); // TODO: Change this to the desired route after login, if not then dashboard
	} else {
		next();
	}
});

export default router