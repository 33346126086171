<template>
	<div class="layout-un-auth app-top-pad-with-height">
		<div class="grad-bg" />
		<div class="layout-un-auth-inner">
			<AtomLogo 
				theme="light" 
				size="medium"
				class="un-auth-logo"
				/>
			<router-view v-slot="{ Component }">
				<transition name="page-fade" mode="out-in">
					<component :is="Component" />
				</transition>
			</router-view>
		</div>
	</div>
</template>

<script>

import AtomLogo from '@/components/atoms/AtomLogo.vue'

export default {
	name: 'LayoutUnAuth',
	components: {
		AtomLogo
	}
}

</script>

<style lang="sass">

.layout-un-auth
	display: flex
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0

	.grad-bg
		position: fixed
		top: 0
		left: 0
		right: 0
		bottom: 0
		z-index: -1
		background-image: url('~@/assets/images/bg-grad-blue.png')
		background-size: 100% 100%

	.layout-un-auth-inner
		width: 100%
		display: flex
		flex-direction: column
		align-items: center
		justify-content: flex-start
		@include momentumScrollY()

	.un-auth-logo
		margin-top: 40px
		margin-bottom: 40px



</style>