<template>
	<div class="atom-confirm-switch">
		<div 
			class="switch-track" 
			:style="{ '--color-changer': bgColor }"
			>
			{{ switchValue ? 'Confirmed' : 'Confirm' }}

			<div 
				class="flash" 
				:class="{ active: switchValue }"
				/>

			<div 
				class="toggle"
				ref="toggle"
				:style="{ transform: togglePosition }"
				>
				<AtomIcon
					:icon="!switchValue ? 'padlock-locked' : 'padlock-unlocked'"
					:color="!switchValue ? 'grey' : 'green'"
					size="small"
					/>
			</div>
		</div>
	</div>
</template>

<script>

import Hammer from 'hammerjs'

import AtomIcon from '@/components/atoms/AtomIcon.vue'

export default {
	name: 'AtomConfirmSwitch',
	components: {
		AtomIcon
	},
	data() {
		return {
			switchValue: false,
			percentage: 0,
			bgColor: this.calculateColor(0),
			trackWidth: 220
		}
	},
	mounted(){
		const hammerToggle = new Hammer(this.$refs.toggle)
		hammerToggle.get('pan').set({ direction: Hammer.DIRECTION_HORIZONTAL })

		hammerToggle.on('pan', (e) => {
			if(this.percentage < 100){

				// on pan, add a class of 'panning' to the toggle
				this.$refs.toggle.classList.add('panning')

				this.percentage = e.deltaX / this.trackWidth * 100
			}
		})

		hammerToggle.on('panend', () => {
			// on panend, remove the class of 'panning' from the toggle
			this.$refs.toggle.classList.remove('panning')

			if(this.percentage > 60) {
				this.percentage = 100
				this.toggleSwitch()
			}else{
				this.percentage = 0
			}
		})
	},
	watch: {
		percentage(newVal) {
			this.bgColor = this.calculateColor(newVal);
		}
	},
	methods: {
		toggleSwitch() {
			if(this.switchValue) return // prevent from it happening again
			
			this.switchValue = true

			setTimeout(() => {
				this.$emit('confirmed', this.switchValue) // delay for a second to see the sweet animation and then emit the event
			}, 1000)
		},
		calculateColor(percentage) {
			const startColor = [172, 175, 174] // Mid Grey
			const endColor = [119, 180, 23] // Green
			const r = Math.round(startColor[0] + (endColor[0] - startColor[0]) * (percentage / 100))
			const g = Math.round(startColor[1] + (endColor[1] - startColor[1]) * (percentage / 100))
			const b = Math.round(startColor[2] + (endColor[2] - startColor[2]) * (percentage / 100))

			return `rgb(${r}, ${g}, ${b})`
		}
	},
	computed: {
		// calculate the toggle's position over 220px based on percentage
		togglePosition() {
			return `translateX(${(this.percentage * 1.8)}px)`
		}
	}
}

</script>

<style lang="sass">

.atom-confirm-switch
	display: flex
	align-items: center
	justify-content: center
	width: 100%
	height: 40px
	position: relative

	.switch-track
		position: absolute
		width: 220px
		height: 40px
		border-radius: 20px
		display: flex
		align-items: center
		justify-content: center
		font-size: 20px
		font-family: $mainFontBold
		color: $white
		background-color: var(--color-changer)
		overflow: hidden

		&.color-grey
			background-color: $midGrey

		&.color-green
			background-color: $green
			color: $white

		.toggle
			position: absolute
			width: 42px
			height: 42px
			border-radius: 50%
			background-color: $white
			border: 3px solid var(--color-changer)
			top: -1px
			left: -1px
			display: flex
			align-items: center
			justify-content: center
			transition: transform 0.3s

			&.panning
				transition: none

		.flash
			width: 200px
			height: 100px
			position: absolute
			right: -200px
			top: -50%
			background-image: linear-gradient(to left, transparent, white)
			transform: rotate(10deg)
			transition: right 0.9s
			opacity: 0.5

			&.active
				right: 280px

</style>