<template>
	<div class="atom-yes-no">
		<div 
			class="yes-no-option"
			:class="{
				'active': modelValue === true
			}"
			@click="onInput(true)"
			>
			<div class="checkbox" />
			<div class="label">Yes</div>
		</div>
		<div 
			class="yes-no-option"
			:class="{
				'active': modelValue === false
			}"
			@click="onInput(false)"
			>
			<div class="checkbox" />
			<div class="label">No</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'AtomYesNo',
	props: {
		modelValue: {
			type: Boolean,
			required: true
		}
	},
	methods: {
		onInput(value) {
			this.$emit('update:modelValue', value);
		}
	}
}

</script>

<style lang="sass">

.atom-yes-no
	display: flex
	align-items: center
	gap: 15px

	.yes-no-option
		display: flex
		align-items: center

		.checkbox
			width: 20px
			height: 20px
			border: 1px solid $white
			border-radius: 2px
			margin-right: 10px
			display: flex
			align-items: center
			justify-content: center
			position: relative

		.label
			font-size: 14px
			color: $lightGrey
			line-height: 1
			font-family: $mainFontReg
		
		&.active
			.checkbox
				background-color: $white
				border-color: $white

				&:after
					content: ''
					width: 7px
					height: 12px
					border-right: 2px solid $green
					border-bottom: 2px solid $green
					position: absolute
					transform: translateY(-1px) rotate(45deg)

			.label
				color: $white
				font-family: $mainFontBold

</style>