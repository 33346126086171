<template>
	<div 
		class="atom-icon"
		:class="[
			'color-' + color,
			'icon-' + icon,
			'size-' + size
		]"
		>

		<template v-if="icon == 'search'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 22 22">
				<g>
					<path 
						class="fill-color" 
						d="M20.7,19.5l-5.1-5.2c1.1-1.4,1.7-3.1,1.7-4.9,0-4.5-3.6-8.1-8.1-8.1C4.8,1.3,1.1,4.9,1.1,9.4c0,4.4,3.6,8,8.1,8,2.1,0,3.9-.8,5.3-2l5.1,5.2c.2.1.3.2.5.2s.4-.1.5-.2c.4-.4.4-.8.1-1.1ZM9.2,15.9c-3.6,0-6.6-2.9-6.6-6.5s2.9-6.6,6.6-6.6c3.6,0,6.6,2.9,6.6,6.6-.1,3.5-3,6.5-6.6,6.5Z"
						/>
				</g>
			</svg>
		</template>

		<template v-if="icon == 'home'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 31 27">
				<g>
					<path 
						class="fill-color" 
						d="M29.6,13.5c0,0.9-0.7,1.6-1.6,1.6h-1.6v7.8c0,0.1,0,0.3,0,0.4V24c0,1.1-0.9,2-2,2h-0.8c-0.1,0-0.1,0-0.2,0
						s-0.1,0-0.2,0h-1.6h-1.2c-1.1,0-2-0.9-2-2v-1.2v-3.1c0-0.9-0.7-1.6-1.6-1.6h-3.1c-0.9,0-1.6,0.7-1.6,1.6v3.1V24c0,1.1-0.9,2-2,2
						H9.3H7.7c-0.1,0-0.1,0-0.2,0s-0.1,0-0.2,0H6.5c-1.1,0-2-0.9-2-2v-5.5v-0.1V15H3c-0.9,0-1.6-0.7-1.6-1.6c0-0.4,0.1-0.8,0.5-1.2
						L14.4,1.4C14.8,1,15.2,1,15.5,1s0.7,0.1,1,0.3l12.5,11C29.4,12.6,29.6,13,29.6,13.5z"
						/>
				</g>
			</svg>
		</template>

		<template v-if="icon == 'info'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 27 27">
				<g>
					<path 
						class="fill-color" 
						d="M13.5,26C20.4,26,26,20.4,26,13.5S20.4,1,13.5,1S1,6.6,1,13.5S6.6,26,13.5,26z M11.5,17.4h1.2v-3.1h-1.2
						c-0.6,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2h2.3c0.6,0,1.2,0.5,1.2,1.2v4.3h0.4c0.6,0,1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2h-3.9
						c-0.6,0-1.2-0.5-1.2-1.2S10.9,17.4,11.5,17.4z M13.5,7.2c0.9,0,1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
						S12.6,7.2,13.5,7.2z"
						/>
				</g>
			</svg>
		</template>

		<template v-if="icon == 'map'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 21 27">
				<g>
					<path 
						class="fill-color" 
						d="M11.7,25.4c2.5-3.1,8.2-10.7,8.2-15c0-5.2-4.2-9.4-9.4-9.4s-9.4,4.2-9.4,9.4c0,4.3,5.7,11.9,8.2,15
						C9.9,26.2,11.1,26.2,11.7,25.4L11.7,25.4z M10.5,7.3c1.7,0,3.1,1.4,3.1,3.1s-1.4,3.1-3.1,3.1s-3.1-1.4-3.1-3.1S8.8,7.3,10.5,7.3z"
						/>
				</g>
			</svg>
		</template>

		<template v-if="icon == 'user'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 27">
				<path 
					class="fill-color"
					d="M8.911,14.997c-4.81,-0 -8.706,3.896 -8.706,8.706c0,0.801 0.649,1.45 1.45,1.45l18.975,-0c0.801,-0 1.45,-0.649 1.45,-1.45c0,-4.81 -3.897,-8.706 -8.706,-8.706l-4.463,-0Zm2.231,-2.344c3.453,-0 6.25,-2.798 6.25,-6.25c0,-3.451 -2.797,-6.25 -6.25,-6.25c-3.451,-0 -6.25,2.799 -6.25,6.25c0,3.452 2.799,6.25 6.25,6.25" 
					style="fill-rule:nonzero;"
					/>
			</svg>
		</template>

		<template v-if="icon == 'bell'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 26.5 29.4">
				<path 
					class="fill-color" 
					d="M12,3.4c-.9,0-1.6.7-1.6,1.6v.9c-3.6.7-6.3,3.9-6.3,7.7v.9c0,2.3-.8,4.5-2.4,6.2l-.4.4c-.2.5-.3,1.2,0,1.7s.8.9,1.4.9h18.8c.6,0,1.2-.4,1.4-.9.3-.6.2-1.2-.3-1.7l-.4-.4c-1.5-1.7-2.4-3.9-2.4-6.2v-.9c0-3.8-2.7-6.9-6.2-7.7v-.9c0-.9-.6-1.6-1.5-1.6ZM14.2,27.5c.6-.6.9-1.4.9-2.2h-6.2c0,.8.3,1.6.9,2.2s1.4.9,2.2.9,1.7-.3,2.2-.9Z"
					/>
			</svg>
		</template>

		<template v-if="icon == 'padlock-locked'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 55 63">
				<path 
					class="fill-color" 
					d="M10.068,23.82l0,-5.859c0,-9.704 7.875,-17.579 17.579,-17.579c9.705,0 17.58,7.875 17.58,17.579l-0,5.859l1.95,-0c4.308,-0 7.812,3.504 7.812,7.812l0,23.438c0,4.308 -3.504,7.812 -7.812,7.812l-39.063,0c-4.308,0 -7.812,-3.504 -7.812,-7.812l-0,-23.438c-0,-4.308 3.504,-7.812 7.812,-7.812l1.954,-0Zm7.813,-5.859l-0,5.859l19.529,-0l-0,-5.859c-0,-5.396 -4.367,-9.766 -9.763,-9.766c-5.395,-0 -9.766,4.37 -9.766,9.766" 
					style="fill-rule:nonzero;"
					/>
			</svg>
		</template>

		<template v-if="icon == 'padlock-unlocked'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 71 63">
				<path 
					class="fill-color"
					d="M43.405,17.96c0,-5.396 4.371,-9.767 9.767,-9.767c5.396,0 9.762,4.371 9.762,9.767l0,5.858c0,2.163 1.746,3.909 3.909,3.909c2.162,-0 3.904,-1.746 3.904,-3.909l-0,-5.858c-0,-9.704 -7.871,-17.579 -17.575,-17.579c-9.704,-0 -17.579,7.875 -17.579,17.579l-0,5.858l-27.346,0c-4.309,0 -7.809,3.505 -7.809,7.813l0,23.437c0,4.309 3.5,7.813 7.809,7.813l39.062,-0c4.313,-0 7.817,-3.504 7.817,-7.813l-0,-23.437c-0,-4.308 -3.504,-7.813 -7.817,-7.813l-3.904,0l0,-5.858Z" 
					style="fill-rule:nonzero;"
					/>
			</svg>
		</template>

		<template v-if="icon == 'arrow-left'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 20 14">
				<path 
					class="fill-color" 
					d="M17.6,6H4.8l3.3-3.3c.4-.4.4-1,0-1.4-.4-.4-1-.4-1.4,0L1.7,6.3c-.4.4-.4,1,0,1.4h0l5,5c.4.4,1,.4,1.4,0,.4-.4.4-1,0-1.4l-3.3-3.3h12.8c.6,0,1-.4,1-1s-.4-1-1-1Z"
					/>
			</svg>
		</template>

		<template v-if="icon == 'arrow-right'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 20 14">
				<path 
					class="fill-color" 
					d="M2.4,6h12.8l-3.3-3.3c-.4-.4-.4-1,0-1.4s1-.4,1.4,0l5,5c.4.4.4,1,0,1.4h0l-5,5c-.4.4-1,.4-1.4,0s-.4-1,0-1.4l3.3-3.3H2.4c-.6,0-1-.4-1-1s.4-1,1-1Z"
					/>
			</svg>
		</template>

		<template v-if="icon == 'pencil'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 17.8 17.9">
				<polygon 
					class="fill-color" 
					points="6.3 15.3 14.4 7.2 10.6 3.4 2.5 11.5 1 16.9 6.3 15.3"
					/>
				<path 
					class="fill-color" 
					d="M15.3,6.3l1.1-1.1c.6-.6.6-1.5,0-2l-1.7-1.7c-.6-.6-1.5-.6-2,0l-1.2,1.2h0l3.8,3.6h0Z"
					/>
			</svg>
		</template>

		<template v-if="icon == 'filter'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 17 15">
				<path 
					class="fill-color" 
					d="M9.35,14c-.3,0-.6-.1-.8-.3l-1.7-1.3c-.3-.3-.5-.7-.5-1.1v-1.9L1.55,3.6c-.4-.5-.5-1.2-.2-1.7.2-.5.8-.9,1.4-.9h11.6c.6,0,1.2.4,1.4.9.2.5.2,1.2-.2,1.7l-4.8,5.8v3.2c0,.5-.3,1-.8,1.2-.2.2-.4.2-.6.2ZM2.75,2c-.3,0-.4.1-.5.3h0c-.1.2-.1.5,0,.6l5,6.1v2.3c0,.1.1.2.1.3l1.8,1.3c.1.1.3.1.4.1.1-.1.2-.2.2-.3v-3.6l5-6.1c.1-.2.2-.4.1-.6-.1-.3-.3-.4-.6-.4,0,0-11.5,0-11.5,0Z"
					/>
			</svg>
		</template>

		<template v-if="icon == 'document'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 12 16">
				<path 
					class="fill-color" 
					d="M11.436,3.873l-3.75,-0l-0,-3.75l3.75,3.75Zm-11.25,-1.875c-0,-1.034 0.841,-1.875 1.875,-1.875l4.688,-0l-0,3.75c-0,0.519 0.418,0.937 0.937,0.937l3.75,-0l-0,8.438c-0,1.034 -0.841,1.875 -1.875,1.875l-7.5,-0c-1.034,-0 -1.875,-0.841 -1.875,-1.875l-0,-11.25Z"
					/>
			</svg>
		</template>

		<template v-if="icon == 'font-size'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 27 27">
				<g>
					<circle class="fill-color" cx="13.5" cy="13.5" r="12.5"/>
					<g>
						<path style="fill: #fff" d="M14.785,16.608h-1.581c-.177,0-.32-.041-.429-.124-.108-.082-.191-.188-.247-.316l-.52-1.533h-3.344l-.52,1.533c-.044.112-.124.214-.238.305-.115.091-.257.136-.426.136h-1.594l3.405-8.795h2.088l3.404,8.795ZM11.544,13.246l-.814-2.408c-.061-.153-.125-.333-.193-.54-.068-.208-.137-.432-.205-.674-.061.246-.125.474-.193.683s-.133.391-.193.543l-.809,2.396h2.408Z"/>
						<path style="fill: #fff" d="M15.124,11.218c.378-.334.798-.582,1.259-.745.46-.163.954-.245,1.481-.245.378,0,.72.062,1.023.185s.562.294.772.513c.211.22.373.48.486.782.112.302.169.632.169.99v3.911h-.857c-.177,0-.311-.024-.401-.072-.091-.049-.166-.149-.227-.302l-.133-.345c-.157.133-.308.25-.452.351-.146.101-.295.187-.45.257s-.32.122-.495.156-.369.052-.582.052c-.286,0-.546-.037-.779-.111-.233-.075-.432-.185-.595-.329-.162-.146-.288-.325-.377-.541-.089-.215-.133-.463-.133-.745,0-.226.057-.453.169-.686.113-.23.308-.442.586-.634.277-.19.651-.351,1.123-.479.471-.129,1.063-.201,1.78-.217v-.266c0-.366-.075-.631-.227-.794-.15-.163-.365-.245-.643-.245-.222,0-.402.024-.543.073-.142.048-.267.103-.378.162-.11.061-.218.115-.322.163-.105.049-.229.073-.375.073-.129,0-.237-.032-.325-.097-.089-.064-.161-.142-.218-.229l-.338-.586ZM18.492,14.062c-.374.016-.682.048-.924.096-.241.049-.433.109-.573.182s-.238.155-.293.247c-.054.093-.081.193-.081.302,0,.214.058.363.175.45s.288.13.513.13c.242,0,.453-.043.634-.127.182-.085.364-.222.55-.41v-.869Z"/>
					</g>
				</g>
			</svg>
		</template>

		<template v-if="icon == 'bookmark-flat'">
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 25 35">
				<g id="Content">
					<polygon class="fill-color" points="3.633 1.254 3.633 30.654 14.433 24.454 24.033 30.654 24.033 1.254 3.633 1.254"/>
					<polygon style="fill: #fff" points="13.833 8.254 15.533 13.754 21.033 13.754 16.533 17.154 18.233 22.654 13.833 19.254 9.333 22.654 11.033 17.154 6.633 13.754 12.133 13.754 13.833 8.254"/>
				</g>
			</svg>
		</template>

	</div>
</template>

<script>

export default {
	name: 'AtomIcon',
	props: {
		icon: {
			type: String,
			required: true
		},
		color: {
			type: String,
			default: 'white',
			validator: function(value) {
				return ['white', 'grey', 'orange', 'green'].includes(value);
			}
		},
		size: {
			type: String,
			default: 'medium',
			validator: function(value) {
				return ['small', 'medium', 'large'].includes(value);
			}
		}
	}
}

</script>

<style lang="sass">

.atom-icon
	display: flex
	align-items: center
	justify-content: center
	-webkit-tap-highlight-color: transparent // Prevent tap highlight on iOS
	outline: none // Remove outline for focused elements
	// border: 1px solid $red // for debugging layout

	&.size-small
		width: 20px
		height: 20px

	&.size-medium
		width: 25px
		height: 25px

	&.size-large
		width: 30px
		height: 30px

	&.color-white
		.fill-color
			fill: $white

	&.color-grey
		.fill-color
			fill: $midGrey

	&.color-dark-grey
		.fill-color
			fill: $darkGrey

	&.color-orange
		.fill-color
			fill: $orange

	&.color-green
		.fill-color
			fill: $green

	svg
		// border: 1px solid $green // for debugging layout
		width: 100%
		height: 100%

		.fill-color
			transition: fill 300ms


</style>