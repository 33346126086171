import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import axios from 'axios'
import { StatusBar, Style } from '@capacitor/status-bar'
import { Capacitor } from '@capacitor/core'

// Set Axios defaults
axios.defaults.headers.common['Accept'] = 'application/json'

// set a request header of 'ngrok-skip-browser-warning' to avoid the ngrok browser warning - if using ngrok
// axios.defaults.headers.common['ngrok-skip-browser-warning'] = 'true'

if (window.location.hostname === 'frontend.compex.test' && window.location.port === '8080') {
	
	axios.defaults.baseURL = 'https://backend.compex.test'
	document.documentElement.style.setProperty('--app-pad-bottom', '0px')
	console.log('Running on local')

} else if (window.Capacitor) {
	console.log('Running on device')

	axios.defaults.baseURL = 'https://compex-backend.bsu-dev.co.uk/'
	StatusBar.setBackgroundColor({ color: '#FFFFFFFF' })
	StatusBar.setStyle({ style: Style.Light })
	
	if (Capacitor.getPlatform() === 'android') {
		console.log('Android')
		StatusBar.setOverlaysWebView({ overlay: true })
		document.documentElement.style.setProperty('--app-pad-bottom', '0px')
	} else if (Capacitor.getPlatform() === 'ios') {
		console.log('iOS')
		document.documentElement.style.setProperty('--app-pad-bottom', '20px')
	} else {
		console.log('Other')
		document.documentElement.style.setProperty('--app-pad-bottom', '0px')
	}
	
} else {
	console.log('Running on server')
	axios.defaults.baseURL = 'https://backend.compex.test'
	document.documentElement.style.setProperty('--app-pad-bottom', '0px')
	// axios.defaults.baseURL = 'https://compex-backend.bsu-dev.co.uk/'
}

createApp(App).use(store).use(router).mount('#app')
