/**
 * This plugin will set the Authorization header with the token the moment that the token exists in the store.
 */

import axios from 'axios'

const axiosAuthPlugin = store => {
	// Check for token in localStorage on initialization
  const token = localStorage.getItem('token');
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
	
	store.subscribe((mutation, state) => {
		if (mutation.type === 'auth/setToken' || mutation.type === 'auth/clearToken') {
			const token = state.auth.token
			if (token) {
				axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
			} else {
				delete axios.defaults.headers.common['Authorization']
			}
		}
	})
}

export default axiosAuthPlugin