<template>
	<div class="dashboard">
		<div class="container typography-styles">
			<AtomHeading 
				color="grey" 
				align="left"
				size="l"
				weight="normal"
				>
				Documents
			</AtomHeading>

			<div class="documents-list">

				<template v-if="loading">

					<MoleculeDocumentCard 
						v-for="index in 6"
						:key="index"
						:loading="true"
						/>

				</template>
				<template v-else>

					<div 
						v-if="!documents || documents.length === 0"
						class="no-content span-2"
						>
						No documents available
					</div>

					<template v-else>

						<MoleculeDocumentCard 
							v-for="document in documents" 
							:key="document.id"
							:document="document"
							:showDescription="true"
							@click="gotoDocument(document)"
							/>

					</template>

				</template>

			</div>
			
			
		</div>
	</div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

// import AtomButton from '@/components/atoms/AtomButton.vue'
import AtomHeading from '@/components/atoms/AtomHeading.vue'
import MoleculeDocumentCard from '@/components/molecules/MoleculeDocumentCard.vue'

export default {
	name: 'PageDashboard',
	components: {
		// AtomButton,
		AtomHeading,
		MoleculeDocumentCard
	},
	data: () => {
		return {
			loading: true
		}
	},
	mounted() {
		this.getDocuments().then(() => {
			this.loading = false
		})
	},
	methods: {
		...mapActions('auth', {
			logout: 'logout',
		}),
		...mapActions('documents', {
			getDocuments: 'getDocuments',
		}),
		gotoDocument(document) {
			// router push to document id
			this.$router.push({ name: 'Document', params: { id: document.id } })
		}
	},
	computed: {
		...mapGetters('documents', {
			documents: 'getDocuments',
		}),
	}
}
</script>

<style lang="sass">

.dashboard
	display: flex
	border-radius: $borderRadiusLarge $borderRadiusLarge 0 0
	background-color: $lightGrey
	width: 100%
	min-height: $mainContentWindowHeight
	overflow: hidden
	padding: 15px 0

	.documents-list
		padding: 15px 0
		display: grid
		grid-template-columns: repeat(2, 1fr)
		gap: 12px

		.span-2
			grid-column: span 2

	


</style>