
const state = () => ({
	headerOpacity: 0,
	activeFooterIcon: 'home'
})

const getters = {
	getHeaderOpacity: state => state.headerOpacity,
	getActiveFooterIcon: state => state.activeFooterIcon
}

const mutations = {
	setHeaderOpacity(state, opacity) {
		state.headerOpacity = opacity
	},
	setActiveFooterIcon(state, icon) {
		state.activeFooterIcon = icon
	}
}

const actions = {
	
}


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}