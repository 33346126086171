<template>
	<div 
		class="atom-button"
		:class="[
			'color-' + color,
		]"
		>
		<div 
			v-if="loading"
			class="loader"
			/>
		<template v-else>
			{{ label }}
		</template>
	</div>
</template>

<script>

export default {
	name: 'AtomButton',
	props: {
		label: {
			type: String,
			required: true,
		},
		color: {
			type: String,
			default: 'trans-grey',
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
}

</script>

<style lang="sass">

.atom-button
	display: inline-flex
	align-items: center
	justify-content: center
	min-height: 40px
	border-radius: 20px
	text-align: center
	font-size: 18px
	font-family: $mainFontBold
	line-height: 1
	transition: all $transitionLength2
	padding: 5px 25px
	min-width: 200px
	// prevent text selection
	user-select: none

	&.color-trans-grey
		background-color: $transGrey1
		color: #fff

	&.color-orange
		background-color: $orange
		color: #fff

	.loader
		height: 20px
		aspect-ratio: 2.5
		--_g: no-repeat radial-gradient(farthest-side,#fff 90%,#fff0)
		background: var(--_g), var(--_g), var(--_g), var(--_g)
		background-size: 20% 50%
		animation: wiggly 1s infinite linear alternate

@keyframes wiggly
	0%,
	5%
		background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50%
	12.5%
		background-position: calc(0*100%/3) 0   ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50%
	25%
		background-position: calc(0*100%/3) 0   ,calc(1*100%/3) 0   ,calc(2*100%/3) 50% ,calc(3*100%/3) 50%
	37.5%
		background-position: calc(0*100%/3) 100%,calc(1*100%/3) 0   ,calc(2*100%/3) 0   ,calc(3*100%/3) 50%
	50%
		background-position: calc(0*100%/3) 100%,calc(1*100%/3) 100%,calc(2*100%/3) 0   ,calc(3*100%/3) 0
	62.5%
		background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 100%,calc(2*100%/3) 100%,calc(3*100%/3) 0
	75%
		background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 100%,calc(3*100%/3) 100%
	87.5%
		background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 100%
	95%,
	100%
		background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50%
</style>