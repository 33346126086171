<template>
	<div class="atom-content-block">
		<template 
			v-if="block.block_type === 'text' && block.content_type === 'plain'"
			>
			<div 
				class="text-content"
				v-html="block.content_plain"
				/>
		</template>
	</div>
</template>

<script>
export default {
	name: 'AtomContentBlock',
	props: {
		block: {
			type: Object,
			required: true
		}
	},
	mounted() {
		console.log('block', this.block);
	}
};
</script>

<style lang="sass">

.atom-content-block
	display: block

	.text-content
		margin: 0
		padding: 0
		font-size: 14px
		line-height: 1.3
		color: $textGrey
		
		p
			padding: 0 0 15px

		ul, ol
			margin: 0 0 15px 20px
			padding: 0
			list-style-position: outside

		li
			margin: 0 0 8px 0
			padding: 0
			font-size: 14px
			line-height: 1.3
			color: $textGrey
			
			&[data-list="bullet"]
				list-style-type: disc

			&[data-list="ordered"]
				list-style-type: decimal

</style>