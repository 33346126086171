<template>
	<div 
		class="molecule-chapter-card"
		:class="[
			!loading && chapter ? 'has-chapter' : '',
			loading ? 'is-loading' : '',
			!loading && !chapter ? 'no-chapter' : ''
		]"
		>
		<div 
			v-if="loading"
			class="loading-card"
			/>
		<template v-else-if="!loading && chapter">
			<div class="chapter-card-inner">
				<div class="image-container">
					<AtomImage 
						:src="chapter.image"
						:absolute="true"
						/>
				</div>

				<div class="info-container">
					<AtomHeading 
						color="grey"
						size="s"
						weight="bold"
						align="left"
						>
						{{ chapter.name }}
					</AtomHeading>

					<div
						v-if="showDescription"
						class="chapter-description"
						>
						{{ shortDescription }}
					</div>

					<div class="parent-document">
						<AtomIcon 
							icon="document"
							color="dark-grey"
							size="small"
							/>
						<span>{{ document.name }}</span>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			Could not load Chapter
		</template>
	</div>
</template>

<script>

import AtomImage from '@/components/atoms/AtomImage.vue'
import AtomIcon from '@/components/atoms/AtomIcon.vue'
import AtomHeading from '@/components/atoms/AtomHeading.vue'

export default {
	name: 'MoleculeChapterCard',
	components: {
		AtomImage,
		AtomIcon,
		AtomHeading
	},
	props: {
		document: {
			type: [Boolean, Object],
			default: false
		},
		chapter: {
			type: [Boolean, Object],
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		},
		showDescription: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		// limit description to 7 words
		shortDescription() {
			if(this.chapter && this.chapter.description){
				return this.chapter.description.split(' ').slice(0, 7).join(' ') + (this.chapter.description.split(' ').length > 7 ? '...' : '')
			}else{
				return ''
			}
		}
	}
}

</script>

<style lang="sass">

$imageToContentsize: 95px

.molecule-chapter-card
	display: flex
	width: 100%
	min-height: 220px
	background-color: $white
	border-radius: $borderRadiusSmall
	position: relative
	cursor: pointer

	.loading-card
		background: linear-gradient(30deg, lighten($midGrey, 15%) 25%, lighten($midGrey, 20%) 50%, lighten($midGrey, 15%) 75%)
		background-size: 100% 200% 
		animation: gradientLoadingAnimationY 2.6s infinite linear
		position: absolute
		inset: 0
		border-radius: $borderRadiusSmall

	&.has-chapter
		border-radius: $borderRadiusSmall 0 $borderRadiusSmall $borderRadiusSmall

	&.no-chapter
		background-color: lighten($midGrey, 15%)
		padding: 15px
		text-align: center
		align-items: center
		justify-content: center
		flex-direction: column
		gap: 10px
		color: $midGrey
		font-family: $mainFontReg

		&:before
			content: '!'
			font-family: $mainFontBold
			width: 30px
			height: 30px
			border-radius: 50%
			border: 2px solid $midGrey
			display: flex
			align-items: center
			justify-content: center
			font-size: 20px

	.chapter-card-inner
		position: absolute
		inset: 0

		&:before
			content: ''
			position: absolute
			top: 0
			right: 0
			width: 20px
			height: 20px
			background-color: $midGrey
			background: linear-gradient(45deg, $midGrey 0%, $midGrey 20%, lighten($midGrey, 10%) 50%, $midGrey 100%)
			border-radius: 0 0 0 $borderRadiusSmall
			z-index: 1

		&:after
			content: ''
			position: absolute
			top: 0
			right: 0
			width: 0
			height: 0
			border-left: 20px solid $lightGrey
			border-left: 20px solid transparent
			border-right: 20px solid $lightGrey
			border-bottom: 20px solid transparent
			z-index: 2

		.image-container
			position: absolute
			top: 0
			left: 0
			right: 0
			height: $imageToContentsize
			overflow: hidden
			border-radius: $borderRadiusSmall $borderRadiusSmall 0 0

		.info-container
			padding: 12px
			position: absolute
			top: $imageToContentsize
			left: 0
			right: 0
			bottom: 0
			display: flex
			flex-direction: column
			gap: 10px

			.chapter-description
				color: $midGrey
				font-size: 12px
				line-height: 1.2

			.parent-document
				display: flex
				align-items: center
				gap: 5px
				color: $midGrey
				margin-top: auto

				span
					font-size: 11px
					width: 100%
					overflow: hidden
					text-overflow: ellipsis
					white-space: nowrap
	

</style>