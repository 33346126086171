<template>
	<div class="layout-main">
		<div class="grad-bg" />

		<div 
			class="layout-main-header" 
			ref="mainHeader"
			>
			<MoleculeHeader
				class="absolute-header header-trans"
				theme="transparent"
				/>
			<MoleculeHeader
				class="absolute-header header-light"
				theme="light"
				:style="{ opacity: headerOpacity }"
				/>
		</div>

		<div 
			class="layout-main-outer"
			ref="mainOuter"
			>
			<div class="layout-main-inner">
				<router-view v-slot="{ Component }">
					<transition name="page-fade" mode="out-in">
						<component :is="Component" />
					</transition>
				</router-view>
			</div>
		</div>

		<div class="layout-footer-backing" />
		<div class="layout-main-footer">
			<div class="footer-inner">

				<AtomIcon 
					icon="map"
					:color="activeFooterIcon == 'map' ? 'orange' : 'grey'"
					size="large"
					@click="setFooterIcon('map')"
					/>
				<AtomIcon 
					icon="bell"
					:color="activeFooterIcon == 'bell' ? 'orange' : 'grey'"
					size="large"
					@click="setFooterIcon('bell')"
					/>
				<AtomIcon 
					icon="home"
					:color="activeFooterIcon == 'home' ? 'orange' : 'grey'"
					size="large"
					@click="gotoDashboard"
					/>
				<AtomIcon 
					icon="info"
					:color="activeFooterIcon == 'info' ? 'orange' : 'grey'"
					size="large"
					@click="setFooterIcon('info')"
					/>
				<AtomIcon 
					icon="user"
					:color="activeFooterIcon == 'user' ? 'orange' : 'grey'"
					size="large"
					@click="setFooterIcon('user')"
					/>

			</div>			
		</div>

	</div>
</template>

<script>

import { mapGetters } from 'vuex'


import AtomIcon from '@/components/atoms/AtomIcon.vue'
import MoleculeHeader from '@/components/molecules/MoleculeHeader.vue'

export default {
	name: 'LayoutMain',
	components: {
		AtomIcon,
		MoleculeHeader
	},
	data: () => {
		return {
			headerHeight: 0
		}
	},
	mounted(){
		// to find out how much the light header should be faded in basd on scroll position of main body
		this.headerHeight = this.$refs.mainHeader.offsetHeight
		this.$refs.mainOuter.addEventListener('scroll', this.setHeaderOpacity)
		this.setHeaderOpacity()
	},
	methods: {
		setHeaderOpacity(){
			let scrolled = this.$refs.mainOuter.scrollTop
			// console.log(scrolled)

			let output = 0

			// express as a percentage how much 'scrolled' has progressed through headerHeight
			let percentage = scrolled / this.headerHeight

			// // modify the speed of transition
			// percentage = percentage * 1.4

			if(percentage > 0.3){
				output = 1
			}else{
				output = 0
			}

			// // prevent it goign over 1
			// if(percentage > 1){
			// 	percentage = 1
			// }

			// send it
			this.$store.commit('layout/setHeaderOpacity', output)
		},
		setFooterIcon(icon){
			this.$store.commit('layout/setActiveFooterIcon', icon)
		},
		gotoDashboard(){
			this.$router.push({ name: 'Dashboard' })
			this.setFooterIcon('home')
		}
	},
	computed: {
		...mapGetters({
			headerOpacity: 'layout/getHeaderOpacity',
			activeFooterIcon: 'layout/getActiveFooterIcon'
		}),
		// measure distance scrolled in mainOuter
	},
}

</script>

<style lang="sass">

$fadeMaskHeight: $headerHeight

.layout-main
	display: flex
	// position: absolute
	inset: 0
	width: 100dvw
	height: 100dvh

	.grad-bg
		position: fixed
		top: 0
		left: 0
		right: 0
		bottom: 0
		z-index: -1
		background-image: url('~@/assets/images/bg-grad-blue.png')
		background-size: 100% 100%

	.layout-main-header
		position: fixed
		// border: 1px solid $red // for debugging layout
		top: 0
		left: 0
		right: 0
		z-index: 3
		height: $headerHeight

		.absolute-header
			position: absolute
			top: 0
			left: 0
			right: 0

		.header-light
			transition: opacity 700ms

	.layout-main-footer
		position: fixed
		bottom: 0
		left: 0
		right: 0
		height: $footerHeight
		border-top: 1px solid $midGrey
		background-color: rgba($white, 0.8)
		z-index: 3
		backdrop-filter: blur(5px)
		
		.footer-inner
			width: 100%
			display: flex
			justify-content: center
			align-items: center
			gap: 30px
			// border: 1px dashed #f00 // for debugging layout
			position: absolute
			top: 0
			left: 0
			right: 0
			bottom: $appPadBottom
			padding: 0 $sidePaddingMed

	// this is the same height as the footer with a white background, but it sits at a lower z-index than the main content so that a subtle opacity and blur can be applied to the footer top so that we can see a little bit of content behind the footer. because why not.
	.layout-footer-backing
		position: fixed
		bottom: 0
		left: 0
		right: 0
		height: $footerHeight
		z-index: 1
		background-color: $white

	// we have outer and inner divs to allow for the fade mask to be applied to the outer div
	.layout-main-outer
		top: calc($headerHeight - $fadeMaskHeight)
		padding-top: $fadeMaskHeight
		width: 100dvh
		padding-bottom: $footerHeight
		mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) $fadeMaskHeight)
		z-index: 2
		@include momentumScrollY()
		height: 100dvh

		.layout-main-inner
			display: flex
			flex-direction: column
			align-items: center
			justify-content: flex-start
		
		
		


</style>