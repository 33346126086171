<template>
	<div 
		class="atom-logo"
		:class="[
			`size-${size}`
		]"
		>
		<img 
			:src="require(`@/assets/images/logo-compex-${theme}.svg`)"
			alt="CompEx"
			/>
	</div>
</template>

<script>

export default {
	name: 'AtomLogo',
	props: {
		theme: {
			type: String,
			default: 'dark',
			validator: function(value) {
				return ['dark', 'light'].includes(value);
			}
		},
		size: {
			type: String,
			default: 'medium',
			validator: function(value) {
				return ['small', 'medium'].includes(value);
			}
		}
	}
}

</script>

<style lang="sass">

.atom-logo
	display: flex
	align-items: center
	justify-content: center

	img
		width: 100%
		height: auto

	&.size-small
		img
			width: 100px

	&.size-medium
		img
			width: 172px

</style>