import { createStore } from 'vuex'
import auth from './modules/auth'
import layout from './modules/layout'
import documents from './modules/documents'
import axiosAuthPlugin from './plugins/axiosAuth'

const store = createStore({
  modules: {
    auth,
    layout,
    documents
  },
  plugins: [axiosAuthPlugin],
})

export default store
