<template>
	<div 
		class="atom-heading"
		:class="[
			'color-' + color,
			'size-' + size,
			'weight-' + weight,
			'align-' + align,
			spread ? 'spread' : ''
		]"
		>
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'AtomHeading',
	props: {
		color: {
			type: String,
			default: 'white'
		},
		size: {
			type: String,
			default: 'm'
		},
		weight: {
			type: String,
			default: 'bold'
		},
		align: {
			type: String,
			default: 'left'
		},
		spread: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style lang="sass">

.atom-heading
	line-height: 1.3
	@include fontSmoothing
	
	&.align-left
		text-align: left

	&.align-center
		text-align: center

	&.align-right
		text-align: right

	&.weight-bold
		font-family: $mainFontBold

	&.weight-normal
		font-family: $mainFontReg

	&.color-white
		color: $white

	&.color-grey
		color: $textGrey

	&.size-l
		font-size: 23px
		line-height: 1.2

	&.size-m
		font-size: 14px

	&.size-s
		font-size: 13px

	&.spread
		display: flex
		align-items: center
		justify-content: space-between
		width: 100%
	

</style>
