<template>
	<div class="page-register page-layout">
		<div class="container-more-padding">

			<MoleculeHeadingWithIcon
				heading="Create Account"
				icon="arrow-left"
				iconColour="white"
				iconRoute="/login"
				iconSide="left"
				/>

			<template v-if="!registrationComplete">
				<form
					class="form-login"
					@submit.prevent="handleRegister"
					>
					<div class="form-input">
						<AtomInputText
							icon="pencil"
							v-model="form.name"
							placeholder="Full Name"
							align="text"
							/>
					</div>
					<div class="form-input">
						<AtomInputText
							icon="pencil"
							type="password"
							v-model="form.password"
							placeholder="Password"
							align="text"
							/>
					</div>
					<div class="form-input">
						<AtomInputText
							icon="pencil"
							type="password"
							v-model="form.confirmPassword"
							placeholder="Confirm Password"
							align="text"
							/>
					</div>
					<div class="form-input">
						<AtomInputText
							icon="pencil"
							v-model="form.email"
							placeholder="Email"
							align="text"
							/>
					</div>
					<div class="form-input">
						<AtomInputText
							icon="pencil"
							v-model="form.confirmEmail"
							placeholder="Confirm Email"
							align="text"
							/>
					</div>
					<div class="form-footer">
						<strong>Need Help?</strong> <span>Contact us at: <a href="mailto:info@compex-cert.com">info@compex-cert.com</a></span>
					</div>

					<div class="form-terms">
						<div class="term-row">
							<router-link to="" class="tick-label">Privacy & Terms</router-link>
							<AtomYesNo v-model="form.terms" />
						</div>
						<div class="term-row">
							<span class="tick-label">App Notifications</span>
							<AtomYesNo v-model="form.notifications" />
						</div>
						<div class="term-row">
							<span class="tick-label">eNEWS</span>
							<AtomYesNo v-model="form.enews" />
						</div>
					</div>
				</form>

				<ul 
					class="form-errors"
					v-if="(v$.form.$error && v$.form.$errors.length > 0) || errorLog.length > 0"
					>
					<template v-if="errorLog.length > 0">
						<li 
							class="error-message"
							v-for="(error, key) in errorLog"
							:key="key"
							>
							{{ filterErrorMessage(error) }}
						</li>
					</template>
					<template v-if="v$.form.$error && v$.form.$errors.length > 0">
						<li 
							class="error-message"
							v-for="(error, key) in v$.form.$errors"
							:key="key"
							>
							{{ error.$message }}
						</li>
					</template>
				</ul>

				<div class="button-container">
					<AtomButton
						label="Sign Up"
						:color="registerButtonColour"
						:loading="registerLoading"
						@click="handleRegister"
						/>
				</div>

			</template>

			<template v-else>

				<div class="registration-message">
					<p>
						{{ registrationMessage }}
					</p>
					
					<div class="button-container">
						<AtomButton
							label="Sign In"
							color="trans-grey"
							@click="$router.push({ name: 'Login' })"
							/>
					</div>
				</div>

			</template>

			

		</div>
	</div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import useVuelidate from "@vuelidate/core"
import { required, email, minLength, helpers } from "@vuelidate/validators"

import AtomInputText from '@/components/atoms/AtomInputText.vue'
import AtomButton from '@/components/atoms/AtomButton.vue'
import AtomYesNo from '@/components/atoms/AtomYesNo.vue'

import MoleculeHeadingWithIcon from '@/components/molecules/MoleculeHeadingWithIcon.vue'

export default {
	name: 'PageLogin',
	components: {
		AtomInputText,
		AtomButton,
		AtomYesNo,
		MoleculeHeadingWithIcon
	},
	setup: () => ({ v$: useVuelidate() }),
	data: () => {
		return {
			form: {
				name: '',
				password: '',
				confirmPassword: '',
				email: '',
				confirmEmail: '',
				terms: false,
				notifications: false,
				enews: false,
			},
			registerLoading: null,
			errorLog: [],
			registrationComplete: false,
			registrationMessage: 'Test',
		}
	},
	validations() {

		const mustBeTrue = helpers.withMessage('You must agree to the terms', value => value === true)

		return {
			form: {
				name: { 
					required: helpers.withMessage('Name cannot be empty', required), 
				},
				email: { 
					required: helpers.withMessage('Email cannot be empty', required), 
					email: helpers.withMessage('Email must be valid', email)
				},
				confirmEmail: { 
					required: helpers.withMessage('Confirm Email cannot be empty', required), 
					sameAsEmail: helpers.withMessage('Emails must match', (value) => {
						return value === this.form.email
					})
				},
				password: { 
					required: helpers.withMessage('Password cannot be empty', required),
					minLength: helpers.withMessage('Password must be at least 6 characters', minLength(6))
				},
				confirmPassword: { 
					required: helpers.withMessage('Confirm Password cannot be empty', required),
					minLength: helpers.withMessage('Confirm Password must be at least 6 characters', minLength(6)),
					sameAsPassword: helpers.withMessage('Passwords must match', (value) => {
						return value === this.form.password
					})
				},
				terms: { 
					mustBeTrue
				},
			},
		}
	},
	methods: {
		// TODO: subscribe them to notification permissions if form.notifications is true
		
		...mapActions('auth', {
			register: 'register',
		}),
		handleRegister(){
			// clear any previous errors
			this.errorLog = []
			// touch the form to trigger validation
			this.v$.$touch()
			if (!this.v$.$invalid && !this.registerLoading) {
				this.registerLoading = true
				this.register(this.form)
					.then((response) => {
						this.registerLoading = false
						this.registrationComplete = true

						// if they've signed up, we can show a message here.
						console.log('register response', response)
						this.registrationMessage = response.data.message
					})
					.catch((error) => {
						this.registerLoading = false
						this.errorLog.push(error)
					})
			}else{
				// well, they failed then. Vuelidate will give a message, so we don't need to do anything here.
			}
		},
		filterErrorMessage(error){
			console.log('error', JSON.stringify(error))
			if(typeof error === 'string' && error.includes('AxiosError: ')){
				return error.replace('AxiosError: ', '')
			}else if(typeof error === 'object' && error.message){
				return error.message
			}else{
				return error
			}
		}
	},
	computed: {
		...mapGetters('auth', {
			getToken: 'getToken',
			getUser: 'getUser',
			isAuthenticated: 'isAuthenticated',
		}),
		registerButtonColour(){
			// when the login fields are valid, the login button should be orange to indicate that they can proceed.
			if(this.v$.$invalid){
				return 'trans-grey'
			}else{
				return 'orange'
			}
		},
	},
}

</script>

<style lang="sass">

.page-register
	.form-login
		display: flex
		flex-direction: column
		gap: 10px
		padding: 30px 0 20px 0

	.form-input
		position: relative

	.form-footer
		display: flex
		justify-content: space-between
		color: #fff
		font-size: 12px
		margin: 5px 0 15px
		padding: 0 $borderRadiusMedium
		gap: 10px
		align-items: center

		strong
			font-weight: normal
			font-family: $mainFontBold
			font-size: 16px

		a
			text-decoration: underline

	.form-terms
		display: flex
		flex-direction: column
		gap: 15px
		padding: 0 $borderRadiusMedium

		.term-row
			display: flex
			justify-content: space-between
			align-items: center
			gap: 10px

			.tick-label
				font-size: 14px
				color: $white

			a
				text-decoration: underline

	.registration-message
		font-size: 16px
		color: $white
		text-align: center
		padding: 30px 0 40px
		display: flex
		flex-direction: column
		gap: 20px
		line-height: 1.2

	.form-errors
		display: flex
		flex-direction: column
		gap: 10px
		padding: 15px
		background-color: rgba($red, 0.5)
		border-radius: $borderRadiusMedium
		border: 1px solid $transGrey2

		.error-message
			color: $white
			font-size: 14px
			font-family: $mainFontBold

	.button-container
		display: flex
		justify-content: center
		margin: 20px 0 calc(40px + $appPadBottom)

</style>