<template>
	<div class="page-login page-layout">
		<div class="container-more-padding">

			<AtomHeading
				color="white"
				size="l"
				align="center"
				weight="bold"
				>
				Toolbox Guide
			</AtomHeading>

			<form
				class="form-login"
				@submit.prevent="handleLogin"
				>
				<div class="form-input">
					<AtomInputText
						v-model="form.email"
						placeholder="Enter Your Email"
						align="center"
						/>
				</div>
				<div class="form-input">
					<AtomInputText
						type="password"
						v-model="form.password"
						placeholder="Password"
						align="center"
						/>
				</div>
				<div class="form-footer">
					<router-link to="/forgot-password">
						Forgot Password?
					</router-link>
				</div>
			</form>

			<ul 
				class="form-errors"
				v-if="(v$.form.$error && v$.form.$errors.length > 0) || errorLog.length > 0"
				>
				<template v-if="errorLog.length > 0">
					<li 
						class="error-message"
						v-for="(error, key) in errorLog"
						:key="key"
						>
						{{ filterErrorMessage(error) }}
					</li>
				</template>
				<template v-if="v$.form.$error && v$.form.$errors.length > 0">
					<li 
						class="error-message"
						v-for="(error, key) in v$.form.$errors"
						:key="key"
						>
						{{ error.$message }}
					</li>
				</template>
			</ul>


			<div class="options-container">
				<div class="an-option">
					<AtomHeading
						color="white"
						size="l"
						align="center"
						weight="bold"
						>
						Already have an account?
					</AtomHeading>

					<div class="align-center">
						<AtomButton
							label="Sign In"
							:color="loginButtonColour"
							:loading="loginLoading"
							@click="handleLogin"
							/>
					</div>
				</div>

				<div class="an-option">
					<AtomHeading
						color="white"
						size="l"
						align="center"
						weight="bold"
						>
						Don't have an account?
					</AtomHeading>
					
					<div class="align-center">
						<router-link to="/register">
							<AtomButton
								label="Create Account"
								color="trans-grey"
								/>
						</router-link>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import useVuelidate from "@vuelidate/core"
import { required, email, minLength, helpers } from "@vuelidate/validators"

import AtomHeading from '@/components/atoms/AtomHeading.vue'
import AtomInputText from '@/components/atoms/AtomInputText.vue'
import AtomButton from '@/components/atoms/AtomButton.vue'

export default {
	name: 'PageLogin',
	components: {
		AtomHeading,
		AtomInputText,
		AtomButton,
	},
	setup: () => ({ v$: useVuelidate() }),
	data: () => {
		return {
			form: {
				email: '',
				password: '',
			},
			loginLoading: null,
			errorLog: [],
		}
	},
	validations() {
		return {
			form: {
				email: { 
					required: helpers.withMessage('Email cannot be empty', required), 
					email: helpers.withMessage('Email must be valid', email)
				},
				password: { 
					required: helpers.withMessage('Password cannot be empty', required),
					minLength: helpers.withMessage('Password must be at least 6 characters', minLength(6))
				},
			},
		}
	},
	methods: {
		...mapActions('auth', {
			login: 'login',
		}),
		handleLogin(){
			// clear any previous errors
			this.errorLog = []
			// touch the form to trigger validation
			this.v$.$touch()
			if (!this.v$.$invalid && !this.loginLoading) {
				this.loginLoading = true
				this.login(this.form)
					.then(() => {
						this.loginLoading = false
						// send to the dashboard
						this.$router.push({ name: 'Dashboard' })
					})
					.catch((error) => {
						this.loginLoading = false
						this.errorLog.push(error)
					})
			}else{
				// well, they failed then. Vuelidate will give a message, so we don't need to do anything here.
			}
		},
		filterErrorMessage(error){
			console.log('error', JSON.stringify(error))
			if(typeof error === 'string' && error.includes('AxiosError: ')){
				return error.replace('AxiosError: ', '')
			}else if(typeof error === 'object' && error.message){
				return error.message
			}else{
				return error
			}
		}
	},
	computed: {
		...mapGetters('auth', {
			getToken: 'getToken',
			getUser: 'getUser',
			isAuthenticated: 'isAuthenticated',
		}),
		loginButtonColour(){
			// when the login fields are valid, the login button should be orange to indicate that they can proceed.
			if(this.v$.$invalid){
				return 'trans-grey'
			}else{
				return 'orange'
			}
		},
	},
}

</script>

<style lang="sass">

.page-login
	.form-login
		display: flex
		flex-direction: column
		gap: 10px
		padding: 30px 0 20px 0

	.form-input
		position: relative

	.form-footer
		display: flex
		justify-content: flex-end
		color: #fff
		font-size: 12px
		margin-top: 5px
		padding: 0 $borderRadiusMedium

	.options-container
		padding-top: 60px
		display: flex
		flex-direction: column
		gap: 50px
		justify-content: center

		.an-option
			display: flex
			flex-direction: column
			gap: 20px
			justify-content: center

			.align-center
				text-align: center // i dont want the button to be full width, but instead naturally centered at the natural width it will be.

	.form-errors
		display: flex
		flex-direction: column
		gap: 10px
		padding: 15px
		background-color: rgba($red, 0.5)
		border-radius: $borderRadiusMedium
		border: 1px solid $transGrey2

		.error-message
			color: $white
			font-size: 14px
			font-family: $mainFontBold

</style>