<template>
	<div class="page-start">

		<div class="container-more-padding">

			<AtomHeading
				color="white"
				size="l"
				align="center"
				weight="bold"
				>
				Toolbox Guide
			</AtomHeading>

			<div class="disclaimer-box">
				<AtomHeading
					color="white"
					size="l"
					align="center"
					weight="bold"
					>
					Disclaimer
				</AtomHeading>

				<div class="disclaimer-text">
					Mobile devices should only be used where it is
					safe to do so. Use of this app is at the discretion
					of the user and CompEx Certification Limited
					does not accept any liability for improper or
					unsafe use of mobile devices in hazardous areas
					as deﬁned by the site operator or owner.
				</div>
			</div>

			<div class="confirm-slider-container">
				<AtomConfirmSwitch 
					@confirmed="navigateToLogin"
					/>
			</div>

		</div>	
		
	</div>
</template>

<script>

import AtomHeading from '@/components/atoms/AtomHeading.vue'
import AtomConfirmSwitch from '@/components/atoms/AtomConfirmSwitch.vue'

export default {
	name: 'PageStart',
	components: {
		AtomHeading,
		AtomConfirmSwitch
	},
	methods: {
		navigateToLogin() {
			this.$router.push({ name: 'Login' })
		}
	}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass">

.page-start
	display: flex
	flex-direction: column
	align-items: center
	// border: 1px solid $red // for debugging layout
	width: 100%
	gap: 10px

	.disclaimer-box
		margin-top: 10vh

		.disclaimer-text
			margin-top: 20px
			color: $white
			font-size: 16px
			font-family: $mainFontReg
			text-align: center
			line-height: 1.2
			user-select: none

	.confirm-slider-container
		margin-top: 10vh
		// border: 1px solid $red // for debugging layout

</style>
