<template>
	<div class="molecule-heading-with-icon">

		<div class="icon-container">
			<AtomIcon
				v-if="iconSide === 'left'"
				:icon="icon"
				:color="iconColour"
				:size="iconSize"
				@click="iconClick"
				/>
		</div>

		<div class="heading-container">
			<AtomHeading
				:color="headingColour"
				:size="headingSize"
				align="center"
				weight="bold"
				>
				{{ heading }}
			</AtomHeading>
		</div>

		<div class="icon-container">
			<AtomIcon
				v-if="iconSide === 'right'"
				:icon="icon"
				:color="iconColour"
				:size="iconSize"
				@click="iconClick"
				/>
		</div>
	</div>
</template>

<script>

import AtomHeading from '@/components/atoms/AtomHeading.vue'
import AtomIcon from '@/components/atoms/AtomIcon.vue'

export default {
	name: 'MoleculeHeadingWithIcon',
	components: {
		AtomHeading,
		AtomIcon
	},
	props: {
		heading: {
			type: String,
			default: ''
		},
		headingColour: {
			type: String,
			default: 'white'
		},
		headingSize: {
			type: String,
			default: 'l'
		},
		icon: {
			type: String,
			default: ''
		},
		iconColour: {
			type: String,
			default: 'white'
		},
		iconSize: {
			type: String,
			default: 'medium'
		},
		iconRoute: {
			type: [Boolean, String],
			default: false
		},
		iconEmitSubmit: {
			type: Boolean,
			default: false
		},
		iconSide: {
			type: String,
			default: 'left'
		}
	},
	methods: {
		iconClick() {
			if (this.iconRoute) {
				this.$router.push(this.iconRoute)
			}
			if (this.iconEmitSubmit) {
				this.$emit('submit')
			}
		}
	}
}

</script>

<style lang="sass">

.molecule-heading-with-icon
	display: grid
	grid-template-columns: 30px 1fr 30px
	align-items: center
	gap: 5px


</style>