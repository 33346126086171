<template>
	<div 
		class="atom-input-text"
		:class="[
			'align-' + align
		]"
		>
		<input 
			:type="type"
			:value="modelValue" 
			@input="onInput"
			:placeholder="placeholder"
			/>

		<div class="icon" v-if="icon">
			<AtomIcon 
				:icon="icon"
				color="grey"
				size="small"
				/>
		</div>
	</div>
</template>

<script>

import AtomIcon from '@/components/atoms/AtomIcon.vue';

export default {
	name: 'AtomInputText',
	components: {
		AtomIcon
	},
	props: {
		modelValue: {
			type: String,
			required: true
		},
		type: {
			type: String,
			default: 'text',
			validator: (value) => ['text', 'email', 'password'].includes(value)
		},
		placeholder: {
			type: String,
			default: ''
		},
		align: {
			type: String,
			default: 'left',
			validator: (value) => ['left', 'center', 'right'].includes(value)
		},
		icon: {
			type: [Boolean, String],
			default: false
		}
	},
	methods: {
		onInput(event) {
			this.$emit('update:modelValue', event.target.value);
		}
	}
};
</script>

<style lang="sass">

.atom-input-text
	width: 100%
	position: relative

	input
		width: 100%
		padding: 0 20px
		border-radius: $borderRadiusMedium
		font-size: 16px
		height: 46px
		border: 1px solid $transGrey2
		background-color: $transGrey1
		line-height: 1
		color: #fff

		&:focus
			outline: none

		&::placeholder
			color: #fff
			opacity: 0.9

	&.align-left
		input
			text-align: left

	&.align-center
		input
			text-align: center

	&.align-right
		input
			text-align: right

	.icon
		width: 46px
		height: 46px
		display: flex
		align-items: center
		justify-content: center
		position: absolute
		top: 0
		right: 0
		pointer-events: none

</style>