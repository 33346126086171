<template>
	<div class="atom-loading-text">
		<div 
			v-if="withHeading"
			class="heading-loader loading-grad"
			/>

		<div class="loading-lines-block">
			<div 
				v-for="index in lines" 
				:key="index"
				class="loading-line loading-grad"
				/>
		</div>
		
	</div>
</template>

<script>
export default {
	name: 'AtomLoadingText',
	props: {
		withHeading: {
			type: Boolean,
			default: false
		},
		lines: {
			type: Number,
			default: 2
		}
	},
}
</script>

<style lang="sass">

.atom-loading-text
	display: flex
	flex-direction: column
	gap: 10px

	.loading-grad
		background-color: lighten($midGrey, 20%)
		background: linear-gradient(90deg, lighten($midGrey, 10%) 25%, lighten($midGrey, 15%) 50%, lighten($midGrey, 10%) 75%)
		background-size: 200% 100% 
		animation: gradientLoadingAnimationX 2.6s infinite linear

	.heading-loader
		width: 70%
		height: 20px
		

	.loading-lines-block
		display: flex
		flex-direction: column
		gap: 5px

		.loading-line
			width: 90%
			height: 10px

			&:last-child
				width: 60%

</style>