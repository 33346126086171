import axios from 'axios'

const state = () => ({
	documents: [],
	document: null,
	chapter: null
})

const getters = {
	getDocuments: state => state.documents,
	getDocument: state => state.document,
	getChapter: state => state.chapter
}

const mutations = {
	setDocuments(state, documents) {
		state.documents = documents
	},
	setDocument(state, document) {
		state.document = document
	},
	setChapter(state, chapter) {
		state.chapter = chapter
	}
}

const actions = {
	getDocuments({ commit }) {
		console.log('Fetching documents...')
		return new Promise((resolve, reject) => {
			axios.get('/api/documents')
				.then(response => {
					commit('setDocuments', response.data)
					console.log('documents', response.data)
					resolve(response)
				})
				.catch(error => {
					console.error('Error fetching documents:', error)
					reject(error)
				})
		})
	},
	getDocument({ commit }, id) {
		console.log('Fetching document with id:', id)
		return new Promise((resolve, reject) => {
			axios.get(`/api/documents/${id}`)
				.then(response => {
					console.log('document', response.data)
					commit('setDocument', response.data)
					resolve(response.data)
				})
				.catch(error => {
					console.error('Error fetching document:', error)
					reject(error)
				})
		})
	},
	getChapter({ commit }, id) {
		console.log('Fetching chapter with id:', id)
		return new Promise((resolve, reject) => {
			axios.get(`/api/chapters/${id}`)
				.then(response => {
					console.log('chapter', response.data)
					commit('setChapter', response.data)
					resolve(response.data)
				})
				.catch(error => {
					console.error('Error fetching chapter:', error)
					reject(error)
				})
		})
	}
}


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}